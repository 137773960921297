<template>
  <div>
    <div class="card-media ratio" :style="{ paddingBottom: padding + '%' }">
      <gmap-map
        class="fill-parent"
        :center="center"
        :zoom="17"
        map-type-id="terrain"
        :options="{
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          rotateControl: false,
          fullscreenControl: false
        }"
        style="position: absolute;"
      >
        <gmap-marker
          :position="position"
          :clickable="true"
          :draggable="true"
          @drag="updateCircle"
          @dragend="updatePosition"
        />

        <gmap-circle
          :radius="100"
          v-if="!isGlobal"
          :center="circle"
          :options="{ fillColor: '#689eee', strokeColor: '#5188d8' }"
        />

        <gmap-circle
          v-for="(img, i) in images"
          v-if="!img.is_global"
          :key="i"
          :radius="100"
          :center="img.position"
          :options="{ fillColor: '#e1e1e1', strokeColor: '#a5a5a5' }"
        />
      </gmap-map>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="md-down:col-12 col">
          <mega-input label="Lat" v-model="_lat" />
        </div>
        <div class="md-down:col-12 col">
          <mega-input label="Lng" v-model="_lng" />
        </div>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lat: Number,
    lng: Number,
    isGlobal: Boolean,
    ratio: String,
    images: Array
  },
  data() {
    return {
      center: {
        lat: this.lat,
        lng: this.lng
      },
      position: {
        lat: this.lat,
        lng: this.lng
      },
      circle: {
        lat: this.lat,
        lng: this.lng
      }
    };
  },
  computed: {
    _lat: {
      get() {
        return this.position.lat;
      },
      set(value) {
        this.position.lat = parseFloat(value);
        this.$emit("update", this.position);
      }
    },
    _lng: {
      get() {
        return this.position.lng;
      },
      set(value) {
        this.position.lng = parseFloat(value);
        this.$emit("update", this.position);
      }
    },
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  methods: {
    updatePosition(location) {
      this._lat = location.latLng.lat();
      this._lng = location.latLng.lng();
    },

    updateCircle(location) {
      this.circle.lat = location.latLng.lat();
      this.circle.lng = location.latLng.lng();
    }
  }
};
</script>
